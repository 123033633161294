import cn from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import s from './FeatureBar.module.css'

const FeatureBar = ({ title, description, className, action, hide, close }) => {
  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100': !hide,
      'translate-y-full opacity-0': hide,
    },
    className
  )
  return (
    <div className={rootClassName}>
      <div className={s.rootWrap}>
        <div className="pr-[24px] w-[80%] l:pr-0 l:w-full">
          <span className="block md:inline font-medium ">{title}</span>
          {description && (
            <span className="block mb-6 md:mb-0 md:ml-2 font-medium">
              {description}
            </span>
          )}
        </div>
        {action && action}
        {close && (
          <button onClick={close} className={s.close} aria-label="close">
            <XMarkIcon className="w-full" />
          </button>
        )}
      </div>
    </div>
  )
}

export default FeatureBar
